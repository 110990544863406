import React from "react";
import heroImage from "./hero.jpg"; // Import the image

const Hero = () => {
  return (
    <div
      className="relative h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${heroImage})` }} // Use imported image variable
    >
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative container mx-auto px-4 py-16 flex flex-col items-start justify-center h-full text-left">
        <div
          className="text-white font-poppins font-extrabold text-3xl space-y-1 "
          style={{ marginBottom: "20px" }}
        >
          {" "}
          {/* Adjusted text size */}
          <p>A cozy mountain homestay</p>
          <p>Blending tradition with modern comfort</p>
          <p>With all your desired modern comforts</p>
        </div>
        <a href="https://www.airbnb.com/rooms/1228945952847756636?guests=1&adults=1&s=67&unique_share_id=c6eba8a8-cf31-4a4e-aa36-384bbb880d2d&source_impression_id=p3_1724339649_P3dj9ltNNZ1w92DK">
          {" "}
          <button className="bg-red-700 text-white px-6 py-3 rounded-md hover:bg-red-800">
            Book Now
          </button>
        </a>
      </div>
    </div>
  );
};

export default Hero;
