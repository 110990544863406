"use client";
import React, { useState } from "react";
import logo from "./logo.jpg";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const smoothScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const offset = element.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
      setIsOpen(false); // Close the menu on click (optional)
    }
  };

  return (
    <nav
      className="bg-white shadow-md fixed w-full z-50 transition-all duration-300 ease-in-out"
      style={{ fontFamily: "Poppins, sans-serif" }}
    >
      <div className="container mx-auto px-4 py-2 flex justify-between items-center">
        <div>
          <img src={logo} alt="Logo" style={{ width: "100px" }} />
        </div>
        <div className="md:hidden">
          <button onClick={toggleMenu} className="focus:outline-none">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="black"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
          </button>
        </div>

        <div className="hidden md:flex space-x-8">
          <a
            href="#home"
            className="text-black hover:text-black transition-colors duration-300"
            onClick={() => smoothScroll("home")}
          >
            Home
          </a>
          <a
            href="#about"
            className="text-black hover:text-black transition-colors duration-300"
            onClick={() => smoothScroll("about")}
          >
            About Us
          </a>
          <a
            href="#services"
            className="text-black hover:text-black transition-colors duration-300"
            onClick={() => smoothScroll("services")}
          >
            Services
          </a>
          <a
            href="#gallery"
            className="text-black hover:text-black transition-colors duration-300"
            onClick={() => smoothScroll("gallery")}
          >
            Gallery
          </a>
          <a
            href="#number"
            className="text-black hover:text-black transition-colors duration-300"
            onClick={() => smoothScroll("number")}
          >
            Number
          </a>
        </div>

        <div className="hidden md:block">
          <a href="https://www.airbnb.com/rooms/1228945952847756636?guests=1&adults=1&s=67&unique_share_id=c6eba8a8-cf31-4a4e-aa36-384bbb880d2d">
            <button className="bg-red-700 text-white px-6 py-3 rounded-md hover:bg-red-800 transition-all duration-300">
              Call Now
            </button>
          </a>
        </div>
      </div>

      <div
        className={`md:hidden transition-max-h duration-300 ease-in-out overflow-hidden ${
          isOpen ? "max-h-screen" : "max-h-0"
        }`}
      >
        <a
          href="#home"
          className="block text-black hover:text-black py-2 px-4 transition-colors duration-300"
          onClick={() => smoothScroll("home")}
        >
          Home
        </a>
        <a
          href="#about"
          className="block text-black hover:text-black py-2 px-4 transition-colors duration-300"
          onClick={() => smoothScroll("about")}
        >
          About Us
        </a>
        <a
          href="#services"
          className="block text-black hover:text-black py-2 px-4 transition-colors duration-300"
          onClick={() => smoothScroll("services")}
        >
          Services
        </a>
        <a
          href="#gallery"
          className="block text-black hover:text-black py-2 px-4 transition-colors duration-300"
          onClick={() => smoothScroll("gallery")}
        >
          Gallery
        </a>
        <a
          href="#number"
          className="block text-black hover:text-black py-2 px-4 transition-colors duration-300"
          onClick={() => smoothScroll("number")}
        >
          Number
        </a>
        <div className="block py-2 px-4">
          <a href="https://www.airbnb.com/rooms/1228945952847756636?guests=1&adults=1&s=67&unique_share_id=c6eba8a8-cf31-4a4e-aa36-384bbb880d2d&source_impression_id=p3_1724339649_P3dj9ltNNZ1w92DK">
            <button className="bg-red-700 text-white px-6 py-3 rounded-md hover:bg-red-800 transition-all duration-300">
              Call Now
            </button>
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
