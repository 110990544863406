import React from "react";
import { FiHome, FiInfo, FiMail, FiNavigation2 } from "react-icons/fi";
import { FaFacebook } from "react-icons/fa";
import "../../App.css";

const Footer = () => {
  return (
    <footer
      className="bg-white text-gray-800 py-12"
      style={{ fontFamily: "Poppins, sans-serif" }}
    >
      <div className="container mx-auto px-4 flex flex-wrap justify-between">
        <div className="w-full md:w-1/4 mb-8 md:mb-0">
          <div className="flex items-center mb-4">
            <img src="/logo.svg" alt="Logo" className="h-8 w-auto mr-2" />
          </div>
          <p className="text-sm mb-4">
            Discover tranquility at our nature-inspired homestay. Enjoy modern
            comforts intertwined with traditional charm.
          </p>
          <a href="https://www.airbnb.com/rooms/1228945952847756636?guests=1&adults=1&s=67&unique_share_id=c6eba8a8-cf31-4a4e-aa36-384bbb880d2d&source_impression_id=p3_1724339649_P3dj9ltNNZ1w92DK">
            {" "}
            <button className="bg-red-700 text-white px-6 py-3 rounded-md hover:bg-red-800">
              Book Now
            </button>
          </a>
        </div>

        <div className="w-full md:w-1/4 mb-8 md:mb-0">
          <h3 className="text-lg font-bold mb-4">Quick Links</h3>
          <ul className="text-sm">
            <li className="mb-2">
              <FiHome className="inline-block mr-2" />
              Home
            </li>
            <li className="mb-2">
              <FiInfo className="inline-block mr-2" />
              About
            </li>
            <li className="mb-2">
              <FiNavigation2 className="inline-block mr-2" />
              Services
            </li>
            <li className="mb-2">
              <FiMail className="inline-block mr-2" />
              Contact
            </li>
          </ul>
        </div>

        <div className="w-full md:w-1/4 mb-8 md:mb-0">
          <h3 className="text-lg font-bold mb-4">Our Services</h3>
          <ul className="text-sm">
            <li className="mb-2">Camping</li>
            <li className="mb-2">Organic Food</li>
            <li className="mb-2">Hiking Services</li>
            <li className="mb-2">Panorama View</li>
          </ul>
        </div>

        <div className="w-full md:w-1/4 mb-8 md:mb-0">
          <h3 className="text-lg font-bold mb-4">Contact Us</h3>
          <p className="text-sm mb-2">Email: contact@dhitalhomestay.com</p>
          <p className="text-sm mb-4">Phone: +977 9856030858</p>
          <div className="flex">
            <a
              href="https://www.facebook.com/profile.php?id=100064103072841"
              className="mr-2"
            >
              <FaFacebook className="text-[#4CC269] text-xl" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
