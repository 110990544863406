import React from "react";

const Question = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white text-black p-4 font-poppins">
      <h1 className="text-[40px] font-bold text-center mb-4">
        Book Your Holiday with{" "}
        <span style={{ color: "#DC143C" }}>Machhapuchhre Dhital Homestay</span>
      </h1>
      <p className="text-[18px] text-center mb-8">
        Welcome to our family-run organic farm and retreat. <br />
        Enjoy delicious cuisine, fresh air, and rejuvenate amidst stunning
        natural beauty.
      </p>
      <a href="https://www.airbnb.com/rooms/1228945952847756636?guests=1&adults=1&s=67&unique_share_id=c6eba8a8-cf31-4a4e-aa36-384bbb880d2d&source_impression_id=p3_1724339649_P3dj9ltNNZ1w92DK">
        {" "}
        <button className="bg-red-700 text-white px-6 py-3 rounded-md hover:bg-red-800">
          Book Now
        </button>
      </a>
    </div>
  );
};

export default Question;
